import React from 'react';
import { Link, graphql } from 'gatsby';
import * as styles from './style.module.css';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function NotFound() {
  const { t } = useI18next();
  return (
    <div className={styles.notFound}>
      <StaticImage
        src="../images/not-found-img2x.png"
        alt="not found image"
        placeholder="none"
        width={328}
        objectFit="contain"
      />
      <span className={styles.notFoundWord}>{t('notFound')}</span>
      <Link to={'/'} className={styles.goBackLink}>
        <div className={styles.goBackLink}>{t('notFoundBack')}</div>
      </Link>
    </div>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
